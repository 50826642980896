import Step from '@/components/global/Step/Step.vue'
import ProductItem from './components/ProductItem.vue'
import { getProinfo, saveMany, getOrgList, save } from './api'
import groupProductItem from "./components/groupProductItem"
export default {
  name: "Home",
  components: {
    Step,
    ProductItem,
    groupProductItem
  },
  data() {
    return {
      modelShow: false,

      // 面包屑
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
      {
        path: "/activity",
        name: "activity",
        title: "活动专区"
      }, {
        path: "/activesubscriptionPage",
        name: "activesubscriptionPage",
        title: "认购结算"
      }
      ],
      activeName: '',// 活动名称
      activeType: '', // 活动类型
      saleType: '',// 促销方式
      startTime: '',
      invoice: '',//供应商
      billToDTOs: [],
      pageLoad: false,
      goodsNum: 0,
      goodsPrice: 0,
      GoodsList: [], // 商品列表数据
      dataArr: [],
      steps: ['选择商品', '确认订单信息', '提交订单'],
      currentStep: 2,
      RouterParams: {},
      supplierId: '',// 供应商id
      isFX: '',// 该供应商是否为分销 用来判断是否显示转款协议
      isChooseNum: 0,
      proIds: [],
      buyNums: [],
      visible: false,
      cherk: false,
      deposit: 0, // 定金价格 根据定金是否大于0判断跳转页面
      allMoney:0,
      allVolume:0
    };
  },

  mounted() {
    this.getInfo()
  },
  methods: {
    goback() {
      window.close()
      this.$router.go(-1)
    },
    onChange(e) {
      this.cherk = e.target.checked
    },
    // 不同意协议
    disagree() {
      this.cherk = false
      this.visible = false

    },
    // 同意协议
    agree() {
      this.cherk = true
      this.visible = false
    },
    // 获取上一页面传参进行对应处理
    getInfo() {
      this.RouterParams = JSON.parse(this.$route.query.item)
      getOrgList({actId: this.RouterParams.productDtoList[0].activityId}).then(res => {
        if(res.data.code != 0){
          return;
        }
        for (let item in res.data.data) {
          this.billToDTOs = res.data.data[item];//key所对应的value 
        }
        this.supplierId = this.billToDTOs[0].code
        this.billToDTOs.forEach(item => {
          if (item.code == this.supplierId) {
            this.isFX = item.desc
            this.fwOrgName = item.name
          }
          this.$forceUpdate()
        })
      })
      this.invoice = this.RouterParams.fwOrgName
      this.activeName = this.RouterParams.activityName
      this.startTime = this.RouterParams.endDateDesc
      this.activeType = this.RouterParams.activityType
      this.saleType = this.RouterParams.discountTypeName
      this.isChooseNum = this.RouterParams.isChooseNum
      this.getProinfo()
    },
    // 获取商品详情数据
    getProinfo() {
      let proIds = []
      let nums = []
      if (this.saleType !== '套购' && this.saleType !== '组合购') {
        this.RouterParams.productDtoList.forEach(item => {
          if (item.isCheck) {
            proIds.push(item.id)
            nums.push(item.isChooseNum)
          }

        })
      }
      if (this.saleType == '套购') {
        this.RouterParams.arrList.forEach((proItem, index) => {
          if (this.$route.query.proIndex == index) {
            proItem.data.forEach(item => {
              proIds.push(item.id)
              nums.push(proItem.isChooseNum)
              this.isChooseNum = proItem.isChooseNum
            })
          }

        })

      }
      if (this.saleType == '组合购') {
        for (let proItem in this.RouterParams.arrList) {
          if (proItem == this.$route.query.proIndex) {
            this.RouterParams.arrList[proItem].forEach(aaitem => {
              aaitem.child.forEach(item => {
                if (item.isChooseNum !== 0) {
                  proIds.push(item.id)
                  nums.push(item.isChooseNum)
                }
              })
            })
          }
        }
      }

      let data = {
        prdIds: proIds.join(','),
        buyNums: nums.join(',')
      }

      getProinfo(data).then(res => {
        this.GoodsList = res.data.activityList
        this.deposit = res.data.activityList[0].deposit
        res.data.activityList.forEach(item => {
          if (item.discountTypeName !== '套购' && item.discountTypeName !== '组合购') {
            this.allVolume += (item.buyQty * item.volume)
            this.goodsNum += item.buyQty
            this.goodsPrice += (item.buyQty * item.deposit)
            this.buyNums.push(item.buyQty)
            this.proIds.push(item.id)

          }
          if (item.discountTypeName == '套购') {
            this.goodsNum += item.packageNum * this.isChooseNum
            this.goodsPrice += (item.packageNum * item.deposit) * this.isChooseNum
            this.allMoney += (item.packageNum * this.isChooseNum)
            // this.proIds = proIds.join(',')
            // this.buyNums = nums.join(',')
            this.allVolume += (item.packageNum * item.volume) * this.isChooseNum

            this.buyNums.push(item.packageNum * this.isChooseNum)
            this.proIds.push(item.id)
          }
          if (item.discountTypeName == '组合购') {
            this.allVolume += (item.buyQty * item.volume)
            this.goodsNum += item.buyQty
            this.goodsPrice += (item.buyQty * item.deposit)
            this.buyNums.push(item.buyQty)
            this.proIds.push(item.id)
            this.changeData()
          }


        })

      })
    },
    changeData() {
      var map = {},
        dest = [];
      for (var i = 0; i < this.GoodsList.length; i++) {
        var ai = this.GoodsList[i];
        if (!map[ai.productGroup]) {
          dest.push({
            productGroup: ai.productGroup,
            name: ai.productGroupRemark,
            num: ai.packageNum,
            data: [ai]
          });
          map[ai.productGroup] = ai;
        } else {
          for (var j = 0; j < dest.length; j++) {
            var dj = dest[j];
            if (dj.productGroup == ai.productGroup) {
              dj.data.push(ai);
              break;
            }
          }
        }
      }

      this.dataArr = dest;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleBlur() {

    },
    // 修改供应商
    handleChange(value, e) {
      this.supplierId = value
      this.billToDTOs.forEach(item => {
        if (item.code === this.supplierId) {
          this.isFX = item.desc
          this.fwOrgName = item.name
        }
      })
      this.$forceUpdate()
    },
    handleFocus() {

    },

    // 套购订单认购
    subscription() {
      if (this.isFX == 'Y' && this.cherk == false) {
        this.$message.warning('请勾选《转款到此代理商》协议')
        return false
      } else {
        let data = {
          prdIds: this.proIds.join(','),
          buyNums: this.buyNums.join(','),
          orgId: this.supplierId
        }
        this.pageLoad = true
  
        saveMany(data).then(res => {
          if (res && res.data.code == 0 && this.deposit <= 0) {
            this.pageLoad = false
            this.$router.push({
              path: '/submitEndActivity',
              query: {
                code: res.data.data.orderCode,
                fwOrgName:this.fwOrgName,
              }
            })
          } else {
            if (res.data.code == 0) {
              // 跳转支付页面
              this.pageLoad = false
              // 如果接口返回参数payType并且为unionPay 直接打开该页面去电子收银台页面 不走之前流程
              if(res.data.data.payInfo[0].payType && res.data.data.payInfo[0].payType=='unionPay' ) {
                // window.open(res.data.data.payInfo[0].payInfo,' _self')
                window.location.href=res.data.data.payInfo[0].payInfo
                return
              }
              this.$router.push({
                path: '/wxPay',
                query: {
                  payInfo: res.data.data.payInfo[0].payInfo,
                  code: res.data.data.orderCode
                  // fwOrgName:this.fwOrgName,
                }
              })
            } else {
              this.$message.warning(res.data.msg)
              this.pageLoad = false
            }
          }
        })
      }
      


    },
    // 普通订单认购
    signlesubscription() {
      if (this.isFX == 'Y' && this.cherk == false) {
        this.$message.warning('请勾选《转款到此代理商》协议')
        return false
      } 
      else {
        let data = {
          prdIds: this.proIds.join(','),
          buyNums: this.buyNums.join(','),
          orgId: this.supplierId
        }
        this.pageLoad = true
  
        saveMany(data).then(res => {
          if (res && res.data.code == 0 && this.deposit <= 0) {
            this.pageLoad = false
            this.$router.push({
              path: '/submitEndActivity',
              query: {
                code: res.data.data.orderCode,
                fwOrgName:this.fwOrgName,
              }
            })
          } else {
            // // 跳转支付页面
            // this.pageLoad = false
            // this.$router.push({
            //   path: '/wxPay',
            //   query: {
            //     payInfo: res.data.data.payInfo[0].payInfo,
            //     // fwOrgName:this.fwOrgName,
            //   }
            // })
  
            if (res.data.code == 0) {
              // 跳转支付页面
              this.pageLoad = false
              // 如果接口返回参数payType并且为unionPay 直接打开该页面去电子收银台页面 不走之前流程
              if(res.data.data.payInfo[0].payType && res.data.data.payInfo[0].payType=='unionPay' ) {
                // window.open(res.data.data.payInfo[0].payInfo,' _self')
                window.location.href=res.data.data.payInfo[0].payInfo
                return
              }
              this.$router.push({
                path: '/wxPay',
                query: {
                  payInfo: res.data.data.payInfo[0].payInfo,
                  code: res.data.data.orderCode
                }
              })
            } else {
              this.pageLoad = false
              this.$message.warning(res.data.msg)
            }
          }
        })
      }
    }
  }
}